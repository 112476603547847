export const about = () => (`
  <h1>Was ist die PocketBook Cloud?</h1>
  <section>
   <p>Stellen Sie sich eine riesige Bibliothek vor, die Tausende Ihrer Bücher enthält – gut sortiert und jeweils mit Ihren Notizen am Rand versehen. Dank der neuesten Technologie ist diese Bibliothek Realität und steht Ihnen jederzeit zur Verfügung, egal wo Sie sind oder welches Gerät Sie verwenden.</p>
   <p>Die PocketBook Cloud ist ein plattformübergreifender Dienst, der Ihren E-Reader, Ihr Android- oder iOS-Smartphone, Ihr Tablet und Ihren PC miteinander verbindet und in einem einzigen Ökosystem vereint. Sie können mit diesem Dienst Ihre E-Book-Dateien, Lesepositionen und persönlichen Einstellungen synchronisieren. Mit anderen Worten: Sie können auf dem E-Reader mit dem Lesen eines Buches beginnen, auf dem Smartphone weiterlesen und dann wieder zu ihrem PocketBook E-Reader zurückkehren, ohne Zeit mit der Suche nach der richtigen Seite, dem Ändern der Schriftart oder anderen Einstellungen zu verschwenden. Der Onlinedienst <span>PocketBook Cloud</span> wurde entwickelt, um leidenschaftlichen Lesern auf der ganzen Welt das bestmögliche Leseerlebnis zu bieten.</p>
   <p>Unsere E-Reader verwenden die neueste Generation der E-Ink-Technologie, die den Bildschirm des Lesegerätes normalem Papier so ähnlich wie möglich macht und so Ihre Augen schont. Die Displays moderner Smartphones, Tablets und Laptops können dies nicht bieten. Weshalb also auf einem TFT-Bildschirm lesen? Trotz seiner Kompaktheit und Leichtigkeit hat man seinen E-Reader möglicherweise nicht immer zur Hand, beispielsweise, wenn man mit öffentlichen Verkehrsmitteln unterwegs ist oder in im Café auf einen Freund wartet. Es gibt viele Situation, in denen man unverhofft zehn Minuten geschenkt bekommt, um beispielsweise in einen faszinierenden Roman einzutauchen. Die Tatsache, dass Sie Ihren E-Reader gerade nicht dabeihaben, sollte Sie nicht daran hindern.</p>
   <p>Dank der auf Ihrem E-Reader vorinstallierten PocketBook-Cloud-App ist Ihre persönliche Bibliothek noch mobiler und interaktiver. Sie können auf jedem mit der PocketBook Cloud verbundenen Gerät mit ein- und demselben Text arbeiten: Das eBook, das Sie auf Ihrem E-Reader lesen, ist gleichzeitig auch auf Ihrem Smartphone, Tablet oder PC verfügbar.</p>
   <p>Damit dies funktioniert, registrieren Sie sich zuerst im <a href="https://pocketbook.de/de_de/">Online-Buchshop einer PocketBook-Partner-Buchhandlung Ihrer Wahl</a>. Verwenden Sie diese Anmeldedaten (E-Mail und Passwort) nun, um sich auf Ihrem PocketBook E-Reader, einem PC oder in der App <span>PocketBook Reader</span> auf Ihrem iPhone, Android-Smartphone oder Tablet anzumelden. Auf Ihrem PocketBook E-Reader ist die PocketBook Cloud bereits vorinstalliert. Um die Cloud von Ihrem PC aus zu verwenden, rufen Sie die Webseite https://pbcloud-dev1.obreey-products.com auf. Über Ihre Mobilgeräte greifen Sie auf die PocketBook Cloud zu, indem Sie im Play Store oder App Store die App <span>PocketBook Reader</span> herunterladen. Die Dateien Ihrer Bibliothek, Lesepositionen und Einstellungen werde automatisch auf sämtlichen Geräten aktualisiert, auf denen Sie mit denselben Zugangsdaten in der PocketBook Cloud angemeldet sind.</p>
  </section>
`)
export const advantages = () => (`
  <h1>Die Vorteile der PocketBook Cloud</h1>
  <section>
   <p>Eine der größten Motivationen von PocketBook ist es, das E-Reading auf ein völlig neues Niveau zu heben. Dazu werden ständig neue Ideen entwickelt und umgesetzt. Ein hervorragendes Beispiel für eine solche Innovation ist Ihr uneingeschränkter Zugriff auf Ihre E-Books über eine Vielzahl von Geräten. Dank der PocketBook Cloud hat der Leser die Bücher seine persönliche Bibliothek stets zu seiner Verfügung; die Synchronisierung von Dateien zwischen den verschiedenen Geräten ist schnell und unkompliziert.</p>
   <p>Der Speicher der PocketBook Cloud bietet zahlreiche Vorteile: Sie haben Ihre eBooks quasi immer dabei, unabhängig davon, ob Sie einen E-Reader oder ein Smartphone in den Händen halten, an einem Computer sitzen oder auf Ihr Tablet zugreifen. Mithilfe der Cloud kann der E-Reader beispielsweise Inhalte von Ihrem Telefon oder Tablet in nur wenigen Sekunden synchronisieren. Sie müssen das Gerät lediglich mit der PocketBook Cloud verbinden (detaillierte Anweisungen finden Sie hier).</p>
   <p>Um von der PocketBook Cloud zu profitieren, ist es gut zu wissen, wie sie funktioniert. Die Hauptaufgabe dieses Onlinedienstes besteht darin, einen E-Reader mit einem Smartphone oder PC zu synchronisieren. Dafür erhalten Sie 2 GB freien Speicherplatz für Dokumente, die auf allen angeschlossenen Geräten gleichzeitig verfügbar sind. Aber das ist noch nicht alles. Ihre Einstellungen und Lesepositionen werden zusammen mit Ihrer Bibliothek gespeichert. Stellen Sie sich vor, wie praktisch das ist: Ihr Buch ist immer auf der richtigen Seite geöffnet, unabhängig davon, welches Gerät Sie gerade zum Lesen verwenden.</p>
   <p>Die PocketBook Cloud bietet auf einfache und bequeme Weise die Möglichkeit, jederzeit und überall zu lesen. Obwohl der E-Ink-Bildschirm des PocketBook E-Readers viel vorteilhafter zum Lesen ist als TFT-Displays herkömmlicher Geräte, gibt es viele Situationen, in denen ein eReader nicht in der Nähe ist. In diesen Momenten kommt die PocketBook Cloud ins Spiel. Mit ihr können Sie ganz einfach auf Ihrem Smartphone weiterlesen, sobald Sie zwischendrin einen Moment Zeit dazu haben.</p>
   <p>PocketBook ist um die Belange der Leser bemüht und hat ein ganzes Ökosystem geschaffen, das Geräte mit Android, iOS, Windows und Linux kombiniert. Einer der Vorteile unserer eReader ist, dass sie plattformübergreifend genutzt und Inhalte auf ihnen synchronisiert werden können. Sie müssen sich nicht entscheiden – die PocketBook Cloud verbindet sämtliche Gerät miteinander.</p>
   <p>Wir sind zuversichtlich, dass der Offenheit die Zukunft gehört. Dafür ist eine Synchronisierung zwischen verschiedenen Geräten und Plattformen erforderlich. PocketBook – das ist nicht nur ein eReader, sondern eine ganze Bibliothek, die immer bei Ihnen ist.</p>
   <h2>Die PocketBook Cloud wird unterstützt von:</h2>
   <p>- PocketBook E-Readern der Touch-Lux-Reihe ab dem Touch Lux 2</p>
   <p>- PocketBook E-Readern der Touch-HD-Reihe ab dem Touch HD </p>
   <p>- PocketBook E-Readern der Aqua-Reihe ab dem Aqua 2 </p>
   <p>- PocketBook E-Readern der InkPad-Reihe ab dem InkPad</p>
  </section>
`)
export const howToUse = () => (`
  <h1>So verwenden Sie die PocketBook Cloud</h1>
  <section>
   <p>In der PocketBook Сloud befindet sich Ihre virtuelle Bibliothek, auf die Sie von jedem elektronischen Gerät aus, das mit ihr verbunden ist, zugreifen können.  Für höchsten Lesekomfort können mit ihrer Hilfe E-Books, Lesepositionen und persönliche Einstellungen synchronisiert werden. So werden Sie immer zu der Seite weitergeleitet, auf der Sie aufgehört haben zu lesen, unabhängig vom Gerät: E-Reader, Smartphone, Tablet oder PC. Der Dienst wurde so entwickelt, dass er für jeden Benutzer so einfach und klar wie möglich ist. Erfahren Sie mehr und finden Sie heraus, wie Sie die PocketBook Cloud, die App PocketBook Reader für mobile Geräte sowie die Webversion für PCs verwenden.</p>
   <h2>So greifen Sie auf die PocketBook Cloud zu</h2>
   <p>Der erste Schritt, um mit Cloud zu arbeiten, ist Ihre Registrierung im Online-Buchshop einer PocketBook-Partnerbuchhandlung. Erstellen Sie ein persönliches Nutzerkonto, und vergessen Sie nicht, die E-Mail-Adresse, die Sie zur Anmeldung verwendet haben, sowie Ihr Kennwort zu notieren. Sie benötigen diese Angaben, um Ihr PocketBook-Cloud-Konto zu aktivieren.</p>
   <h2>So melden Sie sich in der PocketBook Cloud an</h2>
   <p>Der nächste Schritt ist ganz einfach: Geben Sie die Anmeldedaten des Online-Buchshops auf Ihrem E-Reader ein, um diesen mit der PocketBook Cloud zu verbinden. Der Vorgang dauert nur eine Minute. Stellen Sie sicher, dass Sie mit dem WLAN verbunden sind, bevor Sie sich anmelden.</p>
   <p>Um die PocketBook Cloud auf dem Smartphone oder Tablet zu nutzen, ist es notwendig, die App <span>PocketBook Reader</span> auf Ihr mobiles Gerät zu laden. Die App ist für Android im Play Store bzw. für iOS im App Store kostenlos verfügbar. Geben Sie Ihre Anmeldedaten, mit der Sie sich in der PocketBook-Partnerbuchhandlung angemeldet haben, ein, um das Gerät mit der Cloud zu verbinden.</p>
   <p>Wenn Sie auf dem PC lesen möchten, öffnen Sie die offizielle Website der PocketBook Cloud in einem Browser Ihrer Wahl (z.B. Google Chrome oder Firefox) und melden Sie sich ebenfalls mit Ihren Anmeldedaten an.</p>
   <p>An dieser Stelle ist interessant zu wissen, dass Sie nicht ausschließlich an die Inhalte der Buchhandlung gebunden sind, über die Sie in der PocketBook Cloud angemeldet sind. Sie können Bücher, die Sie in anderen Buchhandlungen gekauften haben, ganz einfach in die PocketBook Cloud laden. Öffnen Sie dazu auf Ihrem PC die Website <a href="https://pbcloud-dev1.obreey-products.com">https://pbcloud-dev1.obreey-products.com</a> und klicken Sie nach Login, auf der Startseite auf die Schaltfläche „Neues Buch hochladen“. Wählen Sie im Popup-Fenster die Datei aus, die Sie herunterladen möchten. Das Buch erscheint nun nicht nur in der Webversion der Cloud, sondern auch im entsprechenden Bereich Ihres E-Readers.</p>
   <p>Jedem Nutzer stehen 2 GB Speicherplatz für seine elektronische Bibliothek zur Verfügung. Nie waren Leser so mobil wie mit unserem plattformübergreifenden Service. Um weitere Informationen zu erhalten, besuchen Sie unseren offiziellen YouTube-Kanal und schauen Sie sich das Video-Handbuch zur Handhabung der PocketBook Cloud an.</p>
  </section>
`)

const seo = {
  about: about,
  advantages: advantages,
  howToUse: howToUse
}
export default seo;