export const about = () => (`
  <h1>Про сервис PocketBook Cloud</h1>
  <section>
  <p>Представьте себе огромную книжную полку, на которой хранятся сотни любимых книг с вашими заметками на полях. Благодаря PocketBook Cloud, такая полка всегда под рукой, независимо от того, где вы находитесь и какой девайс используете.</p>
  <p><strong>PocketBook Cloud – кроссплатформенный облачный сервис</strong>, объединяющий ваш ридер, смартфон на Android или iOS, планшеты и ПК, в единую экосистему. Это позволяет синхронизировать файлы электронных книг, позиции чтения и даже персональные настройки. Вы начинаете читать на ридере, продолжаете на смартфоне, а потом опять возвращаетесь к привычной читалке, не отыскивая каждый раз страницу, на которой остановились, и не меняя раз за разом шрифт и другие параметры отображения.</p>
  <p>PocketBook – это не только современные девайсы, это большая семья, объединяющая страстных книголюбов со всего мира. Именно поэтому был создан <strong>облачный сервис для чтения</strong> где угодно и на любом устройстве.</p>
  <p>В отличие от гаджетов с TFT дисплеями, ридеры используют технологию электронных чернил, которая делает экран максимально близким к обыкновенной бумаге, гарантируя безопасность вашим глазам. Возникает логичный вопрос: зачем читать на обычном TFT дисплее? Дело в том, что E Ink ридер, при всей его компактности и легкости, далеко не всегда рядом. К примеру, вы едете в общественном транспорте или ждете друга в кафе, а ридер оставили дома. Ситуаций, когда можно выкроить 10 минут и окунуться в увлекательное чтение, действительно много, и отсутствие ридера не должно помешать вам.</p>
  <p>Благодаря <strong>PocketBook Cloud, iOS и Android устройства</strong>, электронный ридер и ПК образуют ваше личное виртуальное пространство для чтения и работы с текстами. Обратите внимание, что синхронизированы будут также заметки и закладки, которые вы сделали во время чтения. Таким образом вы можете работать с текстом на любом девайсе, связанным с <strong>PocketBook Cloud: Android</strong> смартфоне, iOS планшете или компьютере с любой операционной системой.</p>
  <p>Кроме того, сервис предоставляет читателям бесплатные 2 ГБ, для хранения в облаке книг и настроек.</p>
  <p>Начните пользоваться вашей интерактивной библиотекой, которая всегда будет с вами!</p>
  </section>
`)
export const advantages = () => (`
  <h1>Преимущества сервиса PocketBook Cloud</h1>
  <section>
  <p>Одним из главных принципов PocketBook является постоянное движение вперед и развитие. Отличный пример такой инновационности – открытый доступ к вашей библиотеке с помощью любого гаджета. Благодаря фирменному облачному сервису <span>PocketBook, синхронизация</span> файлов между разными устройствами осуществляется просто и быстро.</p>
  <p>PocketBook Cloud дает читателям массу преимуществ: ваши книги всегда рядом, независимо от того, держите ли вы в руках ридер или смартфон, сидите за компьютером или с планшетом. К примеру, с помощью облака <span>PocketBook, синхронизация с телефоном</span> займет всего несколько секунд. Все что потребуется сделать – подключить его к сервису</p>
  <p>Основная задача PocketBook Cloud – <span>синхронизация читалки с телефоном</span> или ПК. Вы получаете 2 гигабайта бесплатного пространства для хранения документов, которые становятся доступны одновременно на всех подключенных девайсах. Но это далеко не все. Вместе с библиотекой сохраняются также настройки и позиции чтения. Только представьте насколько это приятно: всегда открывать книгу на нужной странице, независимо от того, каким устройством вы пользуетесь.</p>
  <p>Действительно, <span>читалка с синхронизацией</span> – это удобно. Несмотря на то, что E Ink экран гораздо безопаснее TFT дисплеев обычных гаджетов, существует много ситуаций, когда ридера нет под рукой. Здесь и наступает черед облачного сервиса, который позволяет продолжить чтение незаконченного романа на смартфоне.</p>
  <p>Как много брендов могут похвастаться абсолютной открытостью своих продуктов ради удобства пользователей? Заботясь о читателях, PocketBook создал целую экосистему, объединив девайсы, работающие на Android, iOS, Windows и Linux. Одним из преимуществ наших ридеров является тот факт, что это <span>кроссплатформенная читалка с синхронизацией</span>. Вам не придется выбирать между разными операционными системами и книжными форматами: PocketBook Cloud собрал все популярные гаджеты воедино.</p>
  <p>Компания PocketBook уверенна, что будущее – за открытостью. Поэтому <span>читалка с синхронизацией между устройствами</span> – это предмет нашей особой гордости. Наш продукт – это не просто ридер, это настоящая библиотека, которая всегда с вами.</p>
  <h2>PocketBook Cloud поддерживается такими устройствами:</h2>
  <p>- электронные ридеры серии PocketBook Touch Lux, начиная с модели Touch Lux 2,</p>
  <p>- электронные ридеры серии PocketBook Touch HD, начиная с модели Touch HD,</p>
  <p>- электронные ридеры серии PocketBook Aqua, начиная с модели Aqua 2,</p>
  <p>- электронные ридеры серии PocketBook InkPad, начиная с модели InkPad.</p>
  </section>
`)
export const howToUse = () => (`
  <h1>Как пользоваться сервисом PocketBook Cloud</h1>
  <section>
  <p>Облачный сервис PocketBook – это ваша виртуальная библиотека, в которую можно зайти с любого электронного устройства. При этом не только электронные книги, но и позиции чтения и персональные настройки будут синхронизированы. Это позволит всегда продолжать с нужной страницы, независимо от гаджета: ридер, Android и iOS смартфоны, планшеты и ПК. Сервис создан максимально простым и понятным для каждого читателя, предлагаем познакомиться с ним поближе и узнать, <strong>как пользоваться PocketBook Cloud.</strong></p>
  <p><strong>PocketBook Cloud инструкция</strong> для начинающих</p>
  <p>1. Первый шаг для работы с <strong>PocketBook Cloud – регистрация</strong> в книжном он-лайн магазине. Создав персональный аккаунт, не забудьте записать логин и пароль от него – они понадобятся для подключения ваших устройств к облаку.</p>
  <p>2. Далее все просто: нужно ввести данные аккаунта он-лайн книжного магазина на вашем ридере, чтобы подключить его к <strong>PocketBook Cloud, вход</strong> возможен только при подключенном Wi-Fi и займет не больше одной минуты.</p>
  <p>3. Для синхронизации смартфонов и планшетов, понадобиться приложение PocketBook Reader, которое доступно для бесплатного скачивания в официальных магазинах Android и iOS. Указав логин и пароль от онлайн-магазина, вы привязываете гаджет к облаку.</p>
  <p>4. Если же вы хотите почитать с ПК, нужно зайти с любого браузера на официальный сайт PocketBook Cloud и ввести вышеупомянутые данные аккаунта магазина.</p>
  <p>Вы можете добавить в PocketBook Cloud также электронные книги, приобретенные в других книжных магазинах. Для того, чтобы загрузить такой контент, необходимо зайти на pbcloud-dev1.obreey-products.com и авторизоваться. На стартовой странице нажмите «Upload a new book», и выберете файл для загрузки в документах вашего компьютера. Книга появится не только в веб-версии облака, но и на всех устройствах зарегистрированных в облаке.</p>
  <p>Кроме того, каждому пользователю предоставляется 2 ГБ бесплатного пространства, для хранения своей электронной библиотеки.</p>
  <p>Ваша библиотека еще никогда не была такой мобильной, как с <strong>PocketBook Cloud, аккаунт</strong> которого объединяет ваш ридер с другими устройствами.</p>
  </section>
`)

const seo = {
  about: about,
  advantages: advantages,
  howToUse: howToUse
}
export default seo;