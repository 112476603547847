export const policyHeaderText = () => (`
  <h1 class="PrivacyPolicy__header__heading">Datenschutzerklärung</h1>

  <p>Verantwortlicher:</p>
  <p>Pocketbook International SA</p>
  <p>Adresse: Crocicchio Cortogna, 6, 6900 Lugano, Switzerland</p>
  <p><a href="tel:+41919220705">Telefon: +41 91 922 07 05</a></p>
  <p><a href="tel:+41919220337">Fax: +41 91 922 03 37</a></p>
  <p>Firmenidentifikationsnummer: CHE-416.098.857</p>
  <p>Umsatzsteuer-Identifikationsnummer: CHE-416.098.857 IVA</p>
  <p>Autorisierter Vertreter: Evgenii Zaitsev, Verkaufs und Marketing Direktor</p>
  <p><a href="mailto:privacy@pocketbook-int.com">privacy@pocketbook-int.com</a></p>
`)
export const policyFooterText = () => (`
<p>25.05.2018</p>
`)
export const section1 = () => (`
<p>(1) Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte auf.</p>
  <p>(2) Hinsichtlich der verwendeten Begrifflichkeiten, wie z.B. „personenbezogene Daten“ oder deren „Verarbeitung“ verweisen wir auf die Definitionen aus Art. 4 der Datenschutzgrundverordnung (DS-GVO).</p>
  <p>(3) Der Begriff „Nutzer“ umfasst alle Kategorien von der Datenverarbeitung betroffener Personen. Zu ihnen gehören unsere Geschäftspartner, Kunden, Interessenten und sonstige Besucher unseres Onlineangebotes. Die verwendeten Begrifflichkeiten, wie z.B. „Nutzer“ sind geschlechtsneutral zu verstehen.</p>
  <p>(4) Zu den im Rahmen dieses Onlineangebotes verarbeiteten personenbezogenen Daten der Nutzer gehören:</p>

  <ul>
    <li>Bestandsdaten (z.B. Namen und Adressen von Kunden);</li>
    <li>Kontaktdaten (z.B. E-Mail-Adresse, Telefonnummer);</li>
    <li>Vertragsdaten (z.B. in Anspruch genommene Leistungen bzw. erworbene Produkte, Zahlungsinformationen);</li>
    <li>Nutzungsdaten (z.B. die besuchten Webseiten unseres Onlineangebotes, Interesse an unseren Leistungen und Produkten);</li>
    <li>Inhaltsdaten (z.B. Eingaben im Kontaktformular) sowie; </li>
    <li>Technische Daten (z.B. IP-Adressen, Geräteinformationen).</li>
  </ul>

  <p>(5) Die Verarbeitung der personenbezogenen Daten der Nutzer erfolgt insbesondere zu folgenden Zwecken:</p>

  <ul>
    <li>Bereitstellung des Onlineangebotes, seiner Inhalte und Funktionen;</li>
    <li>Erbringung unserer vertraglichen Leistungen und Services;</li>
    <li>Kundenpflege;</li>
    <li>Beantwortung von Kontaktanfragen und Kommunikation mit den Nutzern;</li>
    <li>Marketing sowie </li>
    <li>Sicherheit des Onlineangebotes.</li>
  </ul>

  <p>(6) Wir verarbeiten personenbezogene Daten der Nutzer nur unter Einhaltung der einschlägigen Datenschutzbestimmungen. Das bedeutet, dass die Daten der Nutzer nur bei Vorliegen einer gesetzlichen Erlaubnis verarbeitet werden. Dies ist insbesondere dann der Fall, wenn die Datenverarbeitung zur Erfüllung unserer vertraglichen Leistungen (z.B. zur Bearbeitung von Aufträgen und Bestellungen) sowie unserer Online-Services erforderlich oder gesetzlich vorgeschrieben ist, eine Einwilligung der Nutzer vorliegt oder auf der Grundlage unserer berechtigten Interessen erfolgt. Als berechtigte Interessen kommen die Analyse, Optimierung, Sicherheit sowie der wirtschaftliche Betrieb unseres Onlineangebotes in Betracht.</p>
  <p>(7) Wir weisen darauf hin, dass die Rechtsgrundlage der Einwilligungen Art. 6 Abs. 1 lit. a) und Art. 7 DS-GVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen Art. 6 Abs. 1 lit. b) DS-GVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen Verpflichtungen Art. 6 Abs. 1 lit. c) DS-GVO und die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten Interessen Art. 6 Abs. 1 lit. f) DS-GVO ist.</p>
`)
export const section2 = () => (`
  <p>(1) Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und Freiheiten natürlicher Personen, geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten. Dadurch sollen die von uns verarbeiteten Daten insbesondere gegen zufällige oder vorsätzliche Manipulationen, Verlust, Löschung oder gegen den unberechtigten Zugriff Dritter geschützt werden. Zu den Sicherheitsmaßnahmen gehört auch die verschlüsselte Übertragung von Daten zwischen Ihrem Browser und unserem Server.</p>
  <p>(2) Daneben haben wir Verfahren eingerichtet, welche die Wahrnehmung von Betroffenenrechten, die Löschung von Daten und eine Reaktion auf die Gefährdung der Daten gewährleisen.</p>
`)
export const section3 = () => (`
  <p>(1) Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und Unternehmen (Auftragsverarbeitern oder Dritten) offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis. Dies gilt z.B. bei einer Übermittlung der Daten an Dritte, wie an den Versanddienstleister gem. Art. 6 Abs. 1 lit. b) DS-GVO, da dies zur Vertragserfüllung erforderlich ist, wenn Sie eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf der Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern etc.).</p>
  <p>(2) Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union oder des Europäischen Wirtschaftsraums) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw. Übermittlung von Daten an Dritte geschieht, erfolgt dies zudem nur, wenn zusätzlich die besonderen Voraussetzungen der Art. 44 ff. DS-GVO vorliegen. D.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B. für die USA durch das „Privacy Shield“) oder bei Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen (so genannte „Standardvertragsklauseln“).</p>
  <p>(3) Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht dies auf Grundlage des Art. 28 DS-GVO.</p>
`)
export const section4 = () => (`
  <p>(1) Wir erheben auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f) DS-GVO Daten über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet (sogenannte Serverlogfiles). Diese Daten sind technisch erforderlich, um Ihnen die jeweilige Website anzuzeigen sowie die Stabilität und Sicherheit zu gewährleisten. Zu den Zugriffsdaten gehören insbesondere der Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, die zuvor besuchte Website und die IP-Adresse.</p>
  <p>(2) Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer von maximal dreißig Tagen gespeichert und danach gelöscht. Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung ausgenommen.</p>
`)
export const section5 = () => (`
  <p>(1) Wir verarbeiten Bestands-, Kontakt- und Vertragsdaten zwecks Erfüllung unserer vertraglichen Verpflichtungen und Serviceleistungen gem. Art. 6 Abs. 1 lit b) DS-GVO. Die in Onlineformularen als verpflichtend gekennzeichneten Eingaben, sind für den Vertragsschluss erforderlich.</p>
  <p>(2) Nutzer können optional ein Nutzerkonto anlegen, in welchem sie unter anderem ihre Bestellungen einsehen oder über das sie im Falle der Registrierung zur Pocketbook Cloud ihre elektronischen Inhalte (insbesondere eBooks) verwalten und nutzen können. Im Rahmen der Registrierung, werden die erforderlichen Pflichtangaben den Nutzern mitgeteilt. Wenn Nutzer ihr Nutzerkonto kündigen, werden ihre Daten im Hinblick auf das Nutzerkonto gelöscht, sofern nicht die weitere Aufbewahrung aus gesetzlichen, insbesondere aus handels- oder steuerrechtlichen Gründen gem. Art. 6 Abs. 1 lit. c) DS-GVO vorgeschrieben. Es obliegt den Nutzern, ihre Daten bei erfolgter Kündigung vor dem Vertragsende zu sichern. Wir sind berechtigt, sämtliche während der Vertragsdauer gespeicherten Daten des Nutzers unwiederbringlich zu löschen.</p>
  <p>(3) Im Rahmen der Registrierung und erneuter Anmeldungen sowie Inanspruchnahme unserer Onlinedienste, speichern wird die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen, als auch der Nutzer an Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer sie ist zur Verfolgung unserer Ansprüche erforderlich oder es besteht hierzu besteht eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c) DS-GVO.</p>
  <p>(4) Wir verarbeiten Nutzungsdaten (z.B., die besuchten Webseiten unseres Onlineangebotes, Interesse an unseren Produkten) und Inhaltsdaten (z.B. Eingaben im Kontaktformular) für Werbezwecke in einem Nutzerprofil, um den Nutzern z.B. Produkthinweise ausgehend von ihren bisher in Anspruch genommenen Leistungen einzublenden.</p>
  <p>(5) Die Löschung der Daten zur Erbringung vertraglicher Leistungen erfolgt nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten. Im Fall der gesetzlichen Archivierungspflichten erfolgt die Löschung nach deren Ablauf (Ende handelsrechtlicher (6 Jahre) und steuerrechtlicher (10 Jahre) Aufbewahrungspflicht). Die Angaben im Kundenkonto verbleiben bis zu dessen Löschung.</p>
`)
export const section6 = () => (`
  <p>Bei der Kontaktaufnahme mit uns (per Kontaktformular oder E-Mail) werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b) DS-GVO verarbeitet.</p>
`)
export const section7 = () => (`
  <p>In unserem Onlineshop können Sie Produktbewertungen abgeben. Ihre Bewertung wird mit Ihrem angegebenen Namen beim jeweiligen Produkt veröffentlicht. Wir empfehlen, ein Pseudonym statt Ihres Klarnamens zu verwenden. Die Angabe des Namens und der E-Mail-Adresse ist erforderlich, alle weiteren Informationen sind freiwillig. Wenn Sie eine Bewertung abgeben, speichern wir weiterhin Ihre IP-Adresse, die wir nach dreißig Tagen löschen. Die Speicherung ist für uns erforderlich, um uns in Fällen einer möglichen Veröffentlichung widerrechtlicher Inhalte gegen Haftungsansprüche verteidigen zu können. Ihre E-Mail-Adresse benötigen wir, um mit Ihnen in Kontakt zu treten, falls ein Dritter Ihre Bewertung als rechtswidrig beanstanden sollte. Rechtsgrundlagen sind Art.6 Abs. 1 lit. b) und f) DS-GVO. Die Bewertungen werden vor Veröffentlichung nicht geprüft. Wir behalten uns vor, Kommentare zu löschen, wenn sie von Dritten als rechtswidrig beanstandet werden. </p>
`)
export const section8 = () => (`
  <p>(1) Mit den nachfolgenden Hinweisen klären wir Sie über die Inhalte unseres Newsletters sowie das Anmelde- und Versandverfahren] sowie Ihre Widerspruchsrechte auf. Indem Sie unseren Newsletter abonnieren, erklären Sie sich mit dem Empfang und den beschriebenen Verfahren einverstanden.</p>
  <p>(2) Wir versenden Newsletter (E-Mails) oder andere elektronische Benachrichtigungen („Newsletter“) mit werblichen Informationen nur mit der Einwilligung der Empfänger oder einer gesetzlichen Erlaubnis. Unsere Newsletter enthalten Informationen zu unseren Produkten und Diensten, Aktionen sowie zu unserem Unternehmen. </p>
  <p>(3) Die Anmeldung zu unserem Newsletter erfolgt in einem sog. Double-Opt-In-Verfahren. D.h. Sie erhalten nach der Anmeldung eine E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten werden. Diese Bestätigung ist notwendig, damit sich niemand mit fremden E-Mail-Adressen anmelden kann. Die Anmeldungen zum Newsletter werden protokolliert, um den Anmeldeprozess entsprechend den rechtlichen Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des Anmelde- und des Bestätigungszeitpunkts sowie der IP-Adresse. Zweck des Verfahrens ist es, Ihre Anmeldung nachweisen und ggf. einen Missbrauch Ihrer persönlichen Daten aufklären zu können. Dies erfolgt auf der Grundlage von Art. 6 Abs. 1 lit. f) DS-GVO.</p>
  <p>(4) Um sich für den Newsletter anzumelden , reicht es aus, wenn Sie Ihre E-Mail-Adresse angeben. Wir speichern Ihre E-Mail-Adresse zum Zweck der Zusendung des Newsletters. Rechtsgrundlage ist Art. 6 Abs. 1 lit. a) DS-GVO.</p>
  <p>(5) Sie können Ihre Einwilligung zum Empfang unseres Newsletters jederzeit widerrufen. Einen Link zur Ausübung Ihres Widerrufsrechtes finden Sie am Ende eines jeden Newsletters. Wenn die Nutzer sich nur zum Newsletter angemeldet und diese Anmeldung gekündigt haben, werden ihre personenbezogenen Daten gelöscht. </p>
`)
export const section9 = () => (`
  <p>(1) Für unser Onlineangebot nutzen wir die Technik von Cookies. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem Endgerät dem von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt (hier durch uns), bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen</p>
  <p>(2) Wir setzen transiente und persistente Cookies ein. Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Endgerät wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser schließen. Persistente Cookies werden zwischen Browsersitzungen auf Ihrem Endgerät gespeichert, sodass wir Ihre Interessen oder Aktionen über mehrere Websites hinweg speichern können. Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer (hier 1 Tag) gelöscht. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.</p>
  <p>(3) Darüber hinaus setzen wir Cookies zur Reichweitenmessung ein, siehe die nachfolgende Ziffer 10 zum Einsatz von Google Analytics. </p>
  <p>(4) Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme von Drittanbieter-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie dann eventuell nicht alle Funktionen unseres Onlineangebotes nutzen können. </p>
  <p>(5)Sie können dem Einsatz von Cookies, die der Reichweitenmessung und Werbezwecken dienen, über die Deaktivierungsseite der Netzwerkwerbeinitiative (<a href="http://optout.networkadvertising.org/">http://optout.networkadvertising.org/</a>) und zusätzlich über die US-amerikanische Webseite (<a href="http://www.aboutads.info/choices">http://www.aboutads.info/choices</a>) oder die europäische Webseite (<a href="http://www.youronlinechoices.com/uk/your-ad-choices/">http://www.youronlinechoices.com/uk/your-ad-choices/</a>) widersprechen.</p>
`)
export const section10 = () => (`
  <p>(1)Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f) DS-GVO) Google Analytics, einen Webanalysedienst der Google LLC („Google“) ein. Google verwendet Cookies. Die durch das Cookie erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten  (<a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a>).</p>
  <p>(2) Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes durch die Nutzer auszuwerten, um Reports über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen und um weitere, mit der Nutzung dieses Onlineangebotes und der Internetnutzung verbundene Dienstleistungen uns gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt werden.</p>
  <p>(3) Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.</p>
  <p>(4) Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Die Nutzer können die Speicherung der Cookies durch eine entsprechende Einstellung ihrer Browser-Software verhindern; die Nutzer können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter folgendem Link verfügbare Browser-Plugin herunterladen und installieren: <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>.</p>
  <p>(5) ) Informationen des Drittanbieters: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, <a href="tel:+3531436 1001">Fax: +353 (1) 436 1001</a>. Nutzerbedingungen: <a href="http://www.google.com/analytics/terms/de.html">http://www.google.com/analytics/terms/de.html</a>, Übersicht zum Datenschutz: <a href="http://www.google.com/intl/de/analytics/learn/privacy.html">http://www.google.com/intl/de/analytics/learn/privacy.html</a>, sowie die Datenschutzerklärung: <a href="http://www.google.de/intl/de/policies/privacy">http://www.google.de/intl/de/policies/privacy</a>.</p>
`)
export const section11 = () => (`
  <p>(1) Innerhalb unseres Onlineangebotes wird aufgrund unserer berechtigten Interessen an Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes und zu diesen Zwecken das sog. "Facebook-Pixel" des sozialen Netzwerkes Facebook, welches von Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird ("Facebook"), eingesetzt. Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten  (<a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=A...</a>).</p>
  <p>(2) Mit Hilfe des Facebook-Pixels ist es Facebook zum einen möglich, die Besucher unseres Onlineangebotes als Zielgruppe für die Darstellung von Anzeigen (sog. "Facebook-Ads") zu bestimmen. Dementsprechend setzen wir das Facebook-Pixel ein, um die durch uns geschalteten Facebook-Ads nur solchen Facebook-Nutzern anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder Produkten, die anhand der besuchten Webseiten bestimmt werden) aufweisen, die wir an Facebook übermitteln (sog. „Custom Audiences“). Mit Hilfe des Facebook-Pixels möchten wir auch sicherstellen, dass unsere Facebook-Ads dem potentiellen Interesse der Nutzer entsprechen und nicht belästigend wirken. Anhand des Facebook-Pixels können wir ferner die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke nachvollziehen, in dem wir sehen, ob Nutzer nach dem Klick auf eine Facebook-Werbeanzeige auf unsere Website weitergeleitet wurden (sog. „Conversion“).</p>
  <p>(3) Die Verarbeitung der Daten durch Facebook erfolgt im Rahmen von Facebooks Datenverwendungsrichtlinie. Dementsprechend sind generelle Hinweise zur Darstellung von Facebook-Ads in der Datenverwendungsrichtlinie von Facebook: <a href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a>. Spezielle Informationen und Details zum Facebook-Pixel und seiner Funktionsweise erhalten Sie im Hilfebereich von Facebook: <a href="https://www.facebook.com/business/help/651294705016616">https://www.facebook.com/business/help/651294705016616</a>.</p>
  <p>(4) Sie können der Erfassung durch den Facebook-Pixel und Verwendung Ihrer Daten zur Darstellung von Facebook-Ads widersprechen. Um einzustellen, welche Arten von Werbeanzeigen Ihnen innerhalb von Facebook angezeigt werden, können Sie die von Facebook eingerichtete Seite aufrufen und dort die Hinweise zu den Einstellungen nutzungsbasierter Werbung befolgen: <a href="https://www.facebook.com/settings?tab=ads">https://www.facebook.com/settings?tab=ads</a>. Die Einstellungen erfolgen plattformunabhängig, d.h. sie werden für alle Geräte, wie Desktopcomputer oder mobile Geräte übernommen.</p>
`)
export const section12 = () => (`
  <p>(1) Nutzer haben das Recht, auf Antrag unentgeltlich Auskunft über die personenbezogenen Daten, die von uns über sie verarbeitet werden, zu erhalten.</p>
  <p>(2) Des Weiteren haben die Nutzer das Recht auf Berichtigung unrichtiger Daten, Einschränkung der Verarbeitung und Löschung ihrer personenbezogenen Daten, sofern zutreffend das Recht auf Datenportabilität und im Fall der Annahme einer unrechtmäßigen Datenverarbeitung das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.</p>
  <p>(3) Ebenso können Nutzer Einwilligungen mit Auswirkung für die Zukunft widerrufen.</p>
`)
export const section13 = () => (`
  <p>(1) Die bei uns gespeicherten Daten werden gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten der Nutzer nicht gelöscht werden, weil sie für andere gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten der Nutzer, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen.</p>
  <p>(2) Nach den gesetzlichen Vorgaben erfolgt die Aufbewahrung für 6 Jahre gemäß § 257 Abs. 1 HGB (z.B. für Handels- und Geschäftsbriefe) sowie für 10 Jahre gemäß § 147 Abs. 1 AO (z.B. für Handelsbücher und Buchungsbelege)</p>
`)
export const section14 = () => (`
  <p>Nutzer können der künftigen Verarbeitung ihrer personenbezogenen Daten entsprechend den gesetzlichen Vorgaben jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.</p>
`)
export const section15 = () => (`
  <p>Die Nutzer werden gebeten, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen oder geänderte Rechtsvorschriften dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.</p>
`)

export const cookiesTermsText = (`<h1>Cookie-Richtlinie</h1>
<section>
<p>HINWEIS DER POCKETBOOK READERS GMBH ZU COOKIES UND NACHVERFOLGUNGSTECHNOLOGIEN
Die POCKETBOOK INTERNATIONAL SA, (hier im Weiteren gleicherweise „PocketBook“, „wir“, „uns“ oder „unser“) betreibt ihre Website <a href="https://pbcloud-dev1.obreey-products.com" target="_blank" rel="noopener noreferrer">https://pbcloud-dev1.obreey-products.com</a> (die „Dienstleistungen“). Wir verwenden eine Reihe von Technologien um zu ergründen, wie die Dienstleistungen verwendet werden. Sie können in den nachfolgenden Informationen Weiteres über diese Technologien und wie man sie steuern kann erfahren. Diese Hinweise zu Cookie- und Rückverfolgungstechnologien sind Teil unserer Datenschutzhinweise.</p>

<p>Cookies & andere Rückverfolgungstechnologien: Wie wir sie verwenden</p>

<p>Wie auch viele andere Unternehmen verwenden wir Cookies und andere Rückverfolgungstechnologien im Zusammenhang mit unseren Dienstleistungen (im Weiteren „Cookies“ genannt, wenn nicht anders bezeichnet).</p>

<p>Cookies sind kleine Dateien, die auf Ihrem Computer oder mobilen Gerät platziert werden, wenn Sie eine Website besuchen. Cookies werden weithin von Online-Dienstleistern verwendet, dass (z.B.) ihre Webseiten oder Dienste funktionieren oder dass sie effizienter funktionieren und auch, um Rückmeldungsinformation bereitzustellen.</p>

<p>Cookies sind kleine Dateien, die auf Ihrem Computer oder mobilen Gerät platziert werden, wenn Sie eine Website besuchen. Cookies werden weithin von Online-Dienstleistern verwendet, dass (z.B.) ihre Webseiten oder Dienste funktionieren oder dass sie effizienter funktionieren und auch, um Rückmeldungsinformation bereitzustellen.</p>

<p>Wir nutzen sowohl sitzungsbezogene als auch residente Rückverfolgungstechnologien. Rückverfolgungstechnologien (z.B. Cookies) können sowohl resident (d.h., sie verbleiben auf Ihrem Computer, bis Sie sie löschen) oder temporär sein (d.h., sie verbleiben nur so lange, bis Sie Ihren Browser schließen).</p>

<p>Wir nutzen auch Erstpartei-Cookies und Cookies Dritter. Erstpartei-Cookies werden von uns genutzt und gesteuert, um Dienste für unsere Dienstleistungen zu erbringen. Cookies Dritter werden von Dritten gesteuert, meistens zu analytischen Zwecken.</p>

<p>Unsere Dienstleistungen verwenden folgende Cookie-Kategorien:</p>

<p>Einfach notwendige Cookies: Notwendige Cookies sind dazu da, eine Website brauchbar zu machen, indem sie grundlegende Funktionen ermöglicht, wie z.B. Seitennavigation und Zugang zu sicheren Bereichen der Website. Die Website funktioniert nicht ausreichend ohne diese Cookies.</p>

<p>Analyse- und leistungsrelevante Cookies: Wir können Cookies verwenden, um die Leistungsfähigkeit unserer Dienstleistungen einzuschätzen, einschließlich als Teil unserer Analysepraktiken, um die Auswahl der für Sie durch unsere Dienstleistungen empfohlenen Inhalte zu verbessern.</p>

<p>Funktionsbezogene Cookies: Wir können Cookies verwenden, um uns zum Beispiel Auskunft darüber zugeben, ob Sie die Dienstleistungen zuvor aufgesucht haben oder ob Sie ein Erstbesucher sind, um uns dabei zu unterstützen herauszufinden, welche Funktionen Sie am meisten interessieren könnten.</p>

<p>Zielrelevante Cookies: Wir können Cookies, um Inhalte zu liefern, einschließlich Anzeigen, die für Ihr Interesse an unseren relevant sind.</p>

<p>Durch Nutzung unserer Dienstleistungen stimmen Sie der Verwendung von Cookies und Rückverfolgungstechnologien und der entsprechenden Verarbeitung der Informationen zu. Sie können jederzeit Ihre Zustimmung zurückziehen, indem Sie die platzierten Cookies löschen und die Cookies in Ihrem Browser deaktivieren.</p>

<p>Ihre Auswahl an Cookies & und wie man sie abwählen kann</p>

<p>Sie können bestimmen, ob Sie die Verwendung von Cookies akzeptieren, und im Folgenden erklären wir, wie Sie Ihre Rechte ausüben können.</p>

<p>Die meisten Browser sind von vornherein so eingestellt, dass sie HTTP-Cookies akzeptieren. Die „Hilfe“-Funktion in der Menüleiste der meisten Browser erklärt Ihnen, wie man die Annahme neuer Cookies unterbinden kann, wie man die Benachrichtigung über den Erhalt neuer Cookies erhält und wie vorhanden Cookies deaktiviert werden können. Für weitergehende Informationen zu HTTP-Cookies und wie man Sie deaktivieren kann, finden Sie Rat unter <a href="http://www.allaboutcookies.org/manage-cookies" target="_blank" rel="noopener noreferrer">http://www.allaboutcookies.org/manage-cookies</a></p>

<p>Die Steuerung des lokalen HTML5-Speichers in Ihrem Browser hängt davon ab, welchen Browser Sie verwenden. Für weitergehende Informationen hinsichtlich Ihres speziellen Browsers konsultieren Sie bitte die Website des Browsers (oft unter „Hilfe“).</p>

<p>Be4achten Sie jedoch bitte, dass Ihnen ohne HTTP-Cookies und lokale Speicherung von HTML5 die volle Funktionsfähigkeit aller unserer Dienstleistungen nicht zur Verfügung steht und dass einige Teile der Dienstleistungen nicht voll funktionsfähig sein könnten.</p>

<p>Sie können auch die Analytik für mobile Apps in der Anzeige „Einstellungen“ auf ihrem Mobilfunkgerät deaktivieren.</p>

<p>Cookies & Rückverfolgungstechnologien, die von den Dienstleistungen von Pocketbook verwendet werden</p>

<p>Die nachfolgende Tabelle legt die Einzelheiten zu einigen von uns verwendeten individuellen Cookies/Nachverfolgungstechnologien und die zwecke, zu denen wir sie verwenden, dar. </p>

<p>Wir sind nicht für Webseiten Dritter und deren Datenschutzpraktiken hinsichtlich der Abwahl von Nachverfolgungsaktivitäten verantwortlich. Nachfolgende Drittparteien, die Informationen von Ihnen bezüglich unserer Dienstleistungen erheben, haben mitgeteilt, dass Sie Informationen von Ihnen über Ihre Verfahrensweisen und Praktiken einholen und, in einigen Fällen, bestimmte einzelne ihrer Aktivitäten abwählen können.</p>

<p>Sie finden weitere Informationen zu einzelnen von uns verwendeten Cookies und die Zweckbestimmungen, für welche wir sie verwenden, in folgender Tabelle:</p>

<div class="CookiesTerms__table">
  <table>
      <thead>
        <tr>
          <th>Cookie</th>
          <th>Dienst</th>
          <th>Zweck</th>
          <th>Wahl bzgl. Datenschutz</th>
          <th>Pocketbookreaders.de</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>accounts.google.com</td>
        <td>Google Analytics</td>
        <td>Analyse- und leistungsbezogen</td>
        <td><a href="http://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noopener noreferrer">http://www.google.com/intl/en/policies/privacy/</a></td>
        <td>ja</td>
      </tr>
    </tbody>
  </table>
</div>


<p>Abschließende Bemerkungen Von Zeit zu Zeit ergänzen wir diese MITTEILUNG zu COOKIE- UND RÜCKVERFOLGUNGSTECHNOLOGIEN, weil sich zum Beispiel unsere Website oder die Regeln bezüglich Cookies. Wir behalten uns das Recht vor, Nachträge zu dieser Cookie-Mitteilung und den in dieser Liste enthaltenen Cookies jederzeit und ohne Ankündigung zu erstellen. Die jeweils neue Cookie-Mitteilung tritt bei Bekanntgabe in Kraft. Falls Sie einer redaktionell überarbeiteten Mitteilung nicht zustimmen, sollten Sie Ihre Prioritäten ändern oder die Beendigung der Nutzung der PocketBook-Seiten in Erwägung ziehen. Wenn Sie auf unsere Dienstleistungen auch nach Inkrafttreten der Änderungen zugreifen bzw. Gebrauch von ihnen machen, stimmen Sie der aktualisierten Cookie-Mitteilung zu. Sie können die neueste Version auf dieser Webseite finden.</p>

<p>Sollten Sie weitere Fragen und/oder Bemerkungen haben, setzen Sie sich bitte mit uns unter <a href="mailto:privacy@pocketbook-int.com">privacy@pocketbook-int.com</a> in Verbindung.</p>
</section>
`)


export const termsText = () => (`
 <section>
          <h2>I. Geltungsbereich, Änderung der Nutzungsbedingungen</h2>
          <p>(1) Die nachfolgenden Nutzungsbedingungen gelten für die Nutzung der Cloud-Dienste der Pocketbook Readers GmbH, Richard-Wagner-Straße 11,01445 Radebeul (nachfolgend „Pocketbook“). Pocketbook stellt den Konsumenten von elektronischen Inhalten, insbesondere den Leserinnen und Lesern von eBooks (nachfolgend „Nutzer“) seinen Cloud-Dienst zur Verfügung. Voraussetzung hierfür ist die vorherige Registrierung des Nutzers, bei der sich der Nutzer mit der Geltung dieser Nutzungsbedingungen einverstanden erklären muss.</p>
          <p>(2) Diese Nutzungsbedingungen regeln ausschließlich das Rechtsverhältnis zwischen Pocketbook und dem Nutzer.</p>
          <p>(3) Pocketbook behält sich vor, diese Nutzungsbedingungen jederzeit mit Wirksamkeit auch innerhalb der bestehenden Vertragsverhältnisse zu ändern. Über derartige Änderungen wird Pocketbook den Nutzer mindestens 30 Kalendertage vor dem geplanten Inkrafttreten der Änderungen in Kenntnis setzen. Sofern der Nutzer nicht innerhalb von 30 Tagen ab Zugang der Mitteilung widerspricht und die Inanspruchnahme der Dienste auch nach Ablauf der Widerspruchsfrist fortsetzt, gelten die Änderungen ab Fristablauf als wirksam vereinbart. Im Falle des Widerspruchs wird der Vertrag zu den bisherigen Bedingungen fortgesetzt. Auf das Widerspruchsrecht und die Rechtsfolgen des Schweigens wird der Nutzer im Falle der Änderungen der Nutzungsbedingungen gesondert hingewiesen.</p>
          <h2>II. Gegenstand der Dienste von Pocketbook</h2>
          <p>(1) Pocketbook stellt den Nutzern seines Cloud-Dienstes die Möglichkeit zur Verfügung, elektronische Inhalte in der Cloud zu speichern, abzurufen, zu verwalten und einzusehen.</p>
          <p>(2) Die Nutzung der Cloud-Dienste ist auf diejenigen Dateiarten beschränkt, die von Pocketbook innerhalb seiner Cloud-Dienste unterstützt werden. Eine darüber hinausgehende Nutzungsmöglichkeit räumt Pocketbook nicht ein.</p>
          <p>(3) Im Übrigen ergeben sich Gegenstand und Umfang der Nutzungsmöglichkeiten aus den innerhalb der Cloud-Dienste vorgesehenen Funktionalitäten.</p>
          <p>(4) Der über die Registrierung abgeschlossene Nutzungsvertrag zwischen Pocketbook und dem jeweiligen Nutzer ist für diesen kostenlos.</p>
          <p>(5) Pocketbook ist jederzeit berechtigt, unentgeltlich bereitgestellte Dienste zu ändern, neue Dienste unentgeltlich oder entgeltlich verfügbar zu machen und die Bereitstellung unentgeltlicher Dienste einzustellen. Pocketbook wird hierbei jeweils auf die berechtigten Interessen der Nutzer Rücksicht nehmen.</p>
          <h2>III. Registrierung</h2>
          <p>(1) Die Nutzung des Cloud-Dienstes von Pocketbook durch den Nutzer setzt voraus, dass er sich zuvor registriert und dabei der Geltung dieser Nutzungsbedingungen zugestimmt hat. Verwendet der Nutzer ein Lesegerät oder eine Leseapplikation von Pocketbook, so zählt der Cloud-Dienst von Pocketbook zu deren Funktionalitäten. Die Registrierung zum Cloud-Dienst einschließlich der Zustimmung zu diesen Nutzungsbedingungen bestimmt sich in diesen Fällen nach den jeweiligen Erwerbs- bzw. Nutzungsvorgängen. Erfolgt der Zugang der Nutzung des Cloud-Dienstes über die Internetseiten von Pocketbook, bestimmt sich die Registrierung nach den folgenden Absätzen 2 und 3. Die Registrierung ist nur volljährigen und unbeschränkt geschäftsfähigen Personen erlaubt. Es besteht kein Anspruch auf Abschluss eines Vertrages.</p>
          <p>(2) Voraussetzung für die Nutzung des Cloud-Dienstes über die Internetseiten von Pocketbook ist es, dass der Nutzer über ein Benutzerkonto bei einem an den Cloud-Dienst angeschlossenen eBook-Shop verfügt. Sofern dies noch nicht der Fall ist, kann sich der Nutzer über den Link „Sie haben noch kein Konto?“ innerhalb des Registrierungsformulars einen eBook-Shop aussuchen, dort ein Benutzerkonto erstellen und anschließend für die Nutzung des Cloud-Dienstes über die Internetseiten von Pocketbook gemäß folgendem Absatz 3 registrieren.</p>
          <p>(3) Bei der Registrierung zur Nutzung des Cloud-Dienstes über die Internetseiten von Pocketbook hat der Nutzer das dort bereitgehaltene Registrierungsformular vollständig auszufüllen. Dazu ist zunächst die Eingabe der E-Mail-Adresse oder des Benutzernamens zum Benutzerkonto des Nutzers in einem an den Cloud-Dienst angeschlossenen eBook-Shop erforderlich. Nach dem Anklicken des Buttons „WEITER“ wird von Pocketbook überprüft, ob die angegebene E-Mail-Adresse als Zugangsdatum eines an den Cloud-Dienst angeschlossenen eBook-Shops vergeben ist. Ist dies der Fall, so wird der betreffende eBook-Shop angezeigt und der Nutzer aufgefordert, sich mit dem dazugehörigen Passwort anzumelden. Etwaige Fehler bei der Eingabe seiner Daten kann der Nutzer innerhalb der jeweiligen Eingabefelder erkennen und in diesen korrigieren. Mit dem Anklicken des Buttons „ANMELDEN“ gibt der Nutzer ein verbindliches Angebot zum Abschluss eines Vertrages zur Nutzung der Cloud-Dienste gegenüber Pocketbook ab. Unmittelbar im Anschluss daran werden die vom Nutzer angegebenen Daten durch Pocketbook überprüft. Sind die Angaben aus Sicht von Pocketbook korrekt und bestehen keine sonstigen Bedenken, schaltet Pocketbook den Zugang zu seinen Cloud-Diensten für den Nutzer frei und benachrichtigt diesen hiervon per E-Mail. Diese E-Mail gilt als Annahmeerklärung von Pocketbook und führt zum Vertragsschluss mit dem Nutzer. Dieser ist ab Zugang der E-Mail zur Nutzung der Cloud-Dienste im Rahmen dieser Nutzungsbedingungen berechtigt. Den Gegenstand und Inhalt seiner Registrierung kann der Nutzer über die Zugangsbenachrichtigung von Pocketbook abspeichern, indem er diese E-Mail über sein E-Mail-Programm speichert. Der Vertragstext, d.h. insbesondere die vom Nutzer angegebenen Daten werden auf den internen Systemen von Pocketbook gespeichert und sind nach Vertragsschluss jederzeit über den Login zu den Cloud-Diensten von Pocketbook zugänglich.</p>
          <p>(4) Vertragssprache ist Deutsch.</p>
          <h2>IV. Pflichten des Nutzers</h2>
          <p>(1) Die Zugangsdaten des Nutzers einschließlich des Passwortes sind von diesen geheim zu halten und unbefugten Dritten nicht zugänglich zu machen. Es liegt in der Verantwortung des Nutzers sicherzustellen, dass der Zugang zu den Cloud-Diensten von Pocketbook ausschließlich durch den Nutzer bzw. durch von ihm bevollmächtigte Personen erfolgt. Steht zu befürchten, dass unbefugte Dritte von den Zugangsdaten Kenntnis erlangt haben oder erlangen werden, ist Pocketbook unverzüglich zu informieren. Pocketbook ist berechtigt, in diesem Fall den Zugang zu den Cloud-Diensten bis zur Klärung des Sachverhaltes zu sperren.</p>
          <p>(2) Der Nutzer haftet für jedwede Nutzung und/oder sonstige Aktivität, die unter seinen Nutzungsdaten ausgeführt werden, nach den gesetzlichen Bestimmungen.</p>
          <p>(3) Der Nutzer ist verpflichtet, bei der Nutzung der Cloud-Dienste die geltenden Gesetze sowie Rechte Dritter zu beachten. Insbesondere liegt es in seiner Verantwortung sicherzustellen, dass seine Dateien und sonstigen Inhalte rechtmäßig erworben wurden. Ebenso ist der Nutzer dafür verantwortlich, dass seine Dateien und sonstigen Inhalte frei von Malware, Viren, Trojanern, Spyware, Würmern oder einem anderen schädlichen Code sind. Pocketbook führt keine Prüfung der Dateien und Inhalte des Nutzers auf Rechtmäßigkeit und Freiheit von schädlichem Code oder dergleichen durch.</p>
          <h2>V. Haftungsfreistellung von Pocketbook</h2>
          <p>Der Nutzer ist verpflichtet, Pocketbook alle Schäden zu ersetzen, die aus der Nichtbeachtung seiner Pflichten aus dem Nutzungsverhältnis über die Cloud-Dienste von Pocketbook sowie insbesondere derjenigen aus Ziffer IV. dieser Nutzungsbedingungen entstehen, und darüber hinaus Pocketbook von allen Ansprüchen Dritter freizustellen, die diese aufgrund der Nichtbeachtung dieser Pflichten durch den Nutzer gegen Pocketbook geltend machen.</p>
          <h2>VI. Nutzungsrechte von Pocketbook</h2>
          <p>(1) Mit dem Abspeichern seiner elektronischen Inhalte in den Cloud-Dienst von Pocketbook räumt der Nutzer Pocketbook jeweils ein unentgeltliches und übertragbares Nutzungsrecht an den jeweiligen Inhalten ein, insbesondere zur Speicherung, Vervielfältigung und Bearbeitung, soweit dies für die ordnungsgemäße Erbringung der Cloud-Dienste durch Pocketbook erforderlich ist.</p>
          <p>(2) Pocketbook behält sich das Recht vor, das Abspeichern von elektronischen Inhalten des Nutzers abzulehnen und/oder bereits abgespeicherte Inhalte ohne vorherige Ankündigung zu bearbeiten, zu sperren oder zu entfernen, sofern das Abspeichern der Inhalte durch den Nutzer oder die abgespeicherten Inhalte selbst zu einem Verstoß gegen diese Nutzungsbedingungen geführt haben oder konkrete Anhaltspunkte dafür vorliegen, dass es zu einem schwerwiegenden Verstoß gegen diese kommen wird. Pocketbook wird hierbei auf die berechtigten Interessen des Nutzers Rücksicht nehmen und das mildeste Mittel zur Abwehr des Verstoßes wählen.</p>
          <h2>VII. Laufzeit, Kündigung</h2>
          <p>(1) Der Vertrag über die Nutzung der Cloud-Dienste von Pocketbook läuft auf unbestimmte Zeit. Der Vertrag kann vom Nutzer ohne Einhaltung einer Kündigungsfrist in Schriftform oder per E-Mail gekündigt werden.</p>
          <p>(2) Pocketbook kann den Vertrag über die Nutzung der Cloud-Dienste mit einer Frist von 14 Tagen kündigen. Das Recht zur Sperrung bleibt hiervon unberührt.</p>
          <p>(3) Das Recht zur Kündigung aus wichtigem Grund bleibt unberührt. Ein wichtiger Grund für Pocketbook liegt insbesondere dann vor, wenn:</p>
          <p>
            <ol type="a"><li>der Nutzer gegen seine Pflichten aus dem Vertrag über die Nutzung der Cloud-Dienste von Pocketbook sowie insbesondere derjenigen aus Ziffer IV. dieser Nutzungsbedingungen verstößt und trotz Mahnung mit Fristsetzung keine fristgemäße Abhilfe schafft. Einer Mahnung bedarf es dann nicht, wenn diese keinen Erfolg verspricht oder der Verstoß so schwerwiegend ist, dass Pocketbook ein Festhalten am Vertrag nicht zumutbar ist. Dabei ist bei einem Verstoß gegen die Pflichten aus Ziffer IV. (Nutzerpflichten) regelmäßig von einem schwerwiegenden Verstoß auszugehen. Ferner kann sich die Schwere des Verstoßes auch daraus ergeben, dass der Nutzer wegen eines vergleichbaren Verstoßes bereits mehrfach abgemahnt wurde;</li><li>Pocketbook die Cloud-Dienste oder seine Geschäftstätigkeit einstellt.</li></ol>
          </p>
          <h2>VIII. Maßnahmen bei rechts- und/oder vertragswidrigem Verhalten des Nutzers</h2>
          <p>(1) Wenn konkrete Anhaltspunkte dafür vorliegen, dass ein Nutzer schuldhaft gegen diese Nutzungsbedingungen oder gegen geltendes Recht verstößt bzw. verstoßen hat, kann Pocketbook vorbehaltlich einer fristlosen Kündigung eine oder mehrere der folgenden Maßnahmen ergreifen:</p>
          <p>
            <ol type="a"><li>den Nutzer abmahnen;</li><li>Inhalte des Nutzers löschen;</li><li>die Nutzung der Cloud-Dienste durch den Nutzer beschränken oder</li><li>den Nutzer vorläufig oder endgültig von der Nutzung der Cloud-Dienste ausschließen (Sperrung).</li></ol>
            Das gleiche gilt im Falle eines sonstigen berechtigten Interesse von Pocketbook an einer oder mehrerer dieser Maßnahmen.
          </p>
          <p>(2) Bei der Wahl einer Maßnahme berücksichtigt Pocketbook auch die berechtigten Interessen des betroffenen Nutzers.</p>
          <p>(3) Sollte ein Nutzer endgültig durch Pocketbook gesperrt worden sein, besteht kein Anspruch auf Wiederherstellung des gesperrten Zugangs zu den Cloud-Diensten von Pocketbook. Der Nutzer darf die Cloud-Dienste von Pocketbook auch nicht über andere Nutzer nutzen und sich nicht erneut registrieren.</p>
          <h2>IX. Haftung von Pocketbook</h2>
          <p>(1) Eine Haftung von Pocketbook besteht – gleich aus welchem Rechtsgrunde – nur</p>
          <p><ol type="a"><li>bei Vorsatz und grober Fahrlässigkeit von Pocketbook, eines gesetzlichen Vertreters, eines leitenden Angestellten oder sonstigen Erfüllungsgehilfen;</li><li>dem Grunde nach bei jeder schuldhaften Verletzung einer wesentlichen Vertragspflicht (wobei der Begriff der wesentlichen Vertragspflicht abstrakt eine solche Pflicht bezeichnet, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung die jeweils andere Partei regelmäßig vertrauen darf), bei Verzug und Unmöglichkeit.</li></ol></p>
          <p>(2) Die Haftung nach Ziff. IX. (1) lit. b) ist bei Vermögens- und Sachschäden auf die Höhe des typischerweise vorhersehbaren Schadens begrenzt.</p>
          <p>(3) Die vorgenannten Haftungsbeschränkungen gelten nicht in den Fällen zwingender gesetzlicher Haftung, insbesondere nach dem Produkthaftungsgesetz, bei Übernahme einer Garantie sowie bei schuldhaften Verletzungen des Lebens, des Körpers und der Gesundheit.</p>
          <p>(4) Pocketbook haftet bei einfach fahrlässig verursachtem Datenverlust nur für den Schaden, der auch bei ordnungsgemäßer und regelmäßiger, der Bedeutung der Daten und Inhalte angemessener Datensicherung durch den Nutzer angefallen wäre; diese Begrenzung gilt nicht, wenn die Datensicherung aus von Pocketbook zu vertretenden Gründen behindert oder unmöglich war.</p>
          <p>(5) Soweit die Haftung von Pocketbook ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung seiner Angestellten, Arbeitnehmer und sonstigen Mitarbeiter und Erfüllungsgehilfen.</p>
          <h2>X. Schlussbestimmungen</h2>
          <p>(1) Pocketbook ist berechtigt, seine Rechte und Pflichten aus dem Nutzungsvertrag ganz oder teilweise auf einen Dritten zu übertragen. Bei einem Wechsel des Vertragspartners (d.h. wenn ein Dritter anstelle von Pocketbook in die sich aus dem Nutzungsvertrag ergebenden Rechte und Pflichten eintritt, ist der Nutzer berechtigt, den Nutzungsvertrag ohne Einhaltung einer Frist in Schriftform oder per E-Mail zu beenden. Eine Abtretung der Rechte des Nutzers aus dem Nutzungsvertrag mit Pocketbook an Dritte ist ausgeschlossen. Insbesondere ist eine Übertragung des Nutzerkontos unzulässig.</p>
          <p>(2) Sollten einzelne Bestimmungen dieser Nutzungsbedingungen ganz oder teilweise unwirksam sein oder werden, so bleiben diese im Übrigen wirksam. Anstelle der unwirksamen Bestimmung gelten die einschlägigen gesetzlichen Vorschriften.</p>
          <br>
          <br>
          <p>Stand: 19.11.2015</p>
      </section>
`)

const privacy = {
  termsOfUse: 'Nutzungsbedingungen',
  cookiesConditions: 'Cookie-Nutzungsbedingungen',
  policyHeaderText: policyHeaderText,
  policyFooterText: policyFooterText,
  privacy01: '1. Anwendungsbereich und Rechtsgrundlagen',
  privacy02: '2. Sicherheitsmaßnahmen',
  privacy03: '3. Weitergabe von Daten an Dritte und Drittanbieter',
  privacy04: '4. Erhebung von Zugriffsdaten und Logfiles',
  privacy05: '5. Erbringung vertraglicher Leistungen',
  privacy06: '6. Kontaktaufnahme',
  privacy07: '7. Produktbewertungen',
  privacy08: '8. Newsletter',
  privacy09: '9. Cookies',
  privacy10: '10. Google Analytics',
  privacy11: '11. Facebook-Marketing-Dienste',
  privacy12: '12. Rechte der Nutzer',
  privacy13: '13. Löschung von Daten',
  privacy14: '14. Widerspruchsrecht',
  privacy15: '15. Änderungen der Datenschutzerklärung',
  section1: section1,
  section2: section2,
  section3: section3,
  section4: section4,
  section5: section5,
  section6: section6,
  section7: section7,
  section8: section8,
  section9: section9,
  section10: section10,
  section11: section11,
  section12: section12,
  section13: section13,
  section14: section14,
  section15: section15,

  termsOfUseText: termsText,
  cookiesTermsText: cookiesTermsText,
}
export default privacy;