const DOMAIN = 'pbcloud-dev1.obreey-products.com'
export const SERVER = 'https://' + DOMAIN
                    //'https://pbcloud-dev1.obreey-products.com'
export const CLOUD_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : SERVER + '/browser'
export const READER_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3232' : SERVER + '/reader_new'
export const SOCKET_URL = process.env.NODE_ENV === 'development' ? 'ws://'+DOMAIN+':8080' : 'wss://'+DOMAIN+'/wss/'

export const URLENCODED = { 'Content-Type': 'application/x-www-form-urlencoded' }
export const API_URL = `${SERVER}/api/v1.0/`
export const API_NEW_URL = `${SERVER}/api/v1.1/`
export const CLIENT_ID = 'qNAx1RDb'
export const CLIENT_SECRET = 'K3YYSjCgDJNoWKdGVOyO1mrROp3MMZqqRNXNXTmh'

export const SUPPORT_MAIL = 'info@pocketbook-int.com'
export const MY_API_KEY = "AIzaSyB3mJooCTmNLGXNztfldrIdjdE14cIyB5I"

export const GTM_ID = "GTM-KDP4TGD"
export const ENABLE_GTM = true

export const COOKIES_ID = '00783868c7a339e2b40046d0b5045153'

export const FACEBOOK_APP_ID = 483025595858921

export const appLinks = {
  android: 'https://play.google.com/store/apps/details?id=com.obreey.reader',
  ios: 'https://itunes.apple.com/ua/app/pocketbook-reader/id805488884?mt=8'
}

export const defaultShopLink = {
  ru: 'https://pocketbook.ua/ua_ru/',
  en: 'https://pocketbook.de/de_en/',
  de: 'https://pocketbook.de/de_de/',
  uk: 'https://pocketbook.ua/ua_ua/'
}

export const hiddenBlocks = {
  account: {
    getMoreSpace: true,
  },
  settings: {
    readRate: true,
    plan: true,
    widgets: false
  },
  readRateWidget: true,
  qrCode: true,

  widgets: false,
  'sidebar.purchased' : false,
  'sidebar.series' : false,
  social: false,
  'sidebar.audioBooks' : false,
  audioBooks: false,
}

export const readerFormats = ['epub', 'pdf', 'acsm'] //'mobi', 'docx'
if (!/Trident\/|MSIE/.test(window.navigator.userAgent) && window.navigator.userAgent.indexOf("Edge") === -1) {
  readerFormats.push('fb2')
  readerFormats.push('fb2.zip')
}

export const ACCEPTABLE_FORMATS = [
  'EPUB',
  'PDF',
  'FB2',
  'TXT',
  'DOCX',
  'HTML',
  'DJVU',
  'RTF',
  'PDB',
  'MOBI',
  'CHM',
  'CBR',
  'ACSM',
  'CBT',
  'CBZ',
  'PRC',
  'ZIP'
]

export const CIS = [
  'AM',
  'AZ',
  'BY',
  'GE',
  'KZ',
  'KG',
  'MD',
  'RU',
  'TM',
  'TJ',
  'UA',
  'UZ'
]

if (!hiddenBlocks.audioBooks) {
  ACCEPTABLE_FORMATS.push('M4B')
  ACCEPTABLE_FORMATS.push('MP3')
}